/**=====================
    13. Form CSS Start
==========================**/
.form-space {
  margin-bottom: -10px;
  > div {
    margin-bottom: 10px;
  }
}
.theme-form {
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  textarea {
    border-color: $form-input-border-color;
    &::-webkit-input-placeholder {
      color: $form-placeholder-color;
      font-size: $form-placeholder-font-size;
      letter-spacing: $form-placeholder-letter-spacing;
      font-weight: $form-placeholder-font-weight;
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="url"],
  input[type="file"],
  textarea,
  select {
    border-color: transparent;
    background-color: $form-input-bg-color;
    font-size: $form-placeholder-font-size;
    color: $form-placeholder-color;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid $light;
    &::-webkit-input-placeholder {
      color: $form-placeholder-color;
      font-size: $form-placeholder-font-size;
      letter-spacing: $form-placeholder-letter-spacing;
      font-weight: $form-placeholder-font-weight;
    }
  }
  input[type="file"] {
    height: 45px;
  }
  select.form-control:not([size]):not([multiple]) {
    border-color: $form-input-border-color;
    font-size: $form-placeholder-font-size;
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }
  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "Or Signup With";
      color: $theme-font-color;
      background: $white;
    }
  }
  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: $theme-font-color;
      background: $white;
      content: "Or Login With";
    }
  }
  .btn-fb,
  .btn-twitter,
  .btn-google,
  .btn-linkedin {
    color: $white;
    width: 100%;
  }
  .btn-fb {
    background-color: $fb;
  }
  .btn-twitter {
    background-color: $twitter;
  }
  .btn-google {
    background-color: $google-plus;
  }
  .btn-linkedin {
    background-color: $linkedin;
  }
}
.form-inline {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;
  .col-form-label {
    margin-right: 5px;
  }
}
.form-control,
.form-select {
  border-color: $light-gray;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 8px;
  &:focus {
    box-shadow: none;
    border-color: $light-gray;
    outline: none;
  }
  &.left-radius {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  &.right-radius {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
input[type="file"] {
  height: 45px;
  // padding: 0.375rem 0.75rem !important;
}
.form-control-color {
  width: 4rem;
  padding: 8px 10px;
}
.input-group-text {
  border-radius: 10px;
  border-color: $light-gray;
}
.input-group {
  &.left-radius {
    .input-group-text {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    input {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
  &.right-radius {
    .input-group-text {
      border-top-right-radius: 30px !important;
      border-bottom-right-radius: 30px !important;
    }
    input {
      border-top-left-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
    }
  }
}
.form-group {
  margin-bottom: 20px;
}
.search-form {
  input {
    border: 1px solid $light-gray;
    padding: 10px 10px 10px 70px;
    border-radius: 10px;
    &:focus {
      outline: none !important;
    }
  }
}
.form-check-input[type=checkbox]{
  opacity: 0.4;
}
.form-control {
  &::-webkit-input-placeholder {
    color: #898989 !important;
    font-weight: 400 !important;
  }
}
.radius-30 {
  border-radius: 30px;
}
.form-label-align-right {
  label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-size: 18px !important;
  background-position: 99% 30% !important;
}
/**=====================
    13. Form CSS Ends
==========================**/