/**=====================
    31. Header CSS Starts
==========================**/
/**======Main Header css Start ======**/
.toggle-sidebar {
  position: absolute;
  top: 27px;
  right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 26px;
    height: auto;
  }
  i {
    font-size: $header-wrapper-nav-icon-size;
    color: $white;
  }
  &:hover {
    &:before {
      height: 40px;
      width: 40px;
      transform: scale(1);
      transition: all 0.3s ease;
    }
    > svg {
      color: var(--theme-default) !important;
      stroke: var(--theme-default) !important;
    }
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
  .flip-card {
    .bookmark-dropdown {
      li {
        input {
          display: none;
        }
      }
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
.left-header {
  .input-group {
    padding: 5px 20px;
    border-radius: 8px;
    background-color: $light;
    .form-control {
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 14px;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        font-weight: 300;
        color: $light-text;
      }
    }
    .input-group-text {
      background-color: transparent;
      border: none;
      padding: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: auto;
        height: 22px;
      }
    }
  }
}
/**=====================
    31. Header CSS Ends
==========================**/