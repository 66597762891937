/**=====================
    51. General widget CSS Start
==========================**/
.static-widget {
  margin-bottom: 40px;
  h6 {
    font-size: 14px;
    font-weight: 400;
    color: $theme-body-sub-title-color;
  }
  .flex-grow-1 {
    align-self: center !important;
  }
  .icon-bg {
    font-size: 30px;
  }
}
.bg-info {
  .d-flex.static-top-widget {
    .align-self-center {
      background-color: $info-color;
    }
  }
}
.bg-primary {
  .d-flex.static-top-widget {
    .align-self-center {
      background-color: var(--theme-default);
    }
  }
}
.bg-secondary {
  .d-flex.static-top-widget {
    .align-self-center {
      background-color: var(--theme-secondary);
    }
  }
}
.bg-danger {
  .d-flex.static-top-widget {
    .align-self-center {
      background-color: $danger-color;
    }
  }
}
// widget joins
.widget-joins {
  .widget-card {
    border: 1px solid $light-gray;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    .widget-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      i {
        font-size: 30px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .icon-bg {
      position: absolute;
      right: 20px;
      bottom: 20px;
      svg {
        opacity: 0.05;
        fill: $light-text;
      }
    }
    h6 {
      color: $light-text;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }
    h5 {
      font-size: 14px;
      span {
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    .font-roboto {
      color: $light-text;
      i {
        font-size: 20px;
        vertical-align: -2px;
      }
      span {
        font-weight: 600;
      }
    }
  }
  .d-flex {
    text-align: center;
    align-items: center;
    .details {
      padding: 1px 0;
    }
    .flex-grow-1 {
      text-align: right;
      > span {
        color: $theme-body-sub-title-color;
      }
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.social-widget-card {
  h6 {
    font-size: 16px;
    font-weight: 500;
    color: $theme-body-sub-title-color;
    margin-bottom: 0;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
  }
  .d-flex {
    align-items: center;
    .social-font {
      width: 70px;
      height: 70px;
      background-color: rgba($primary-color, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: auto;
        height: 40%;
      }
    }
    .flex-grow-1 {
      margin-left: 20px;
    }
    h2 {
      margin-bottom: 0;
    }
  }
  .card-footer {
    .row {
      .col {
        &:nth-child(n + 2) {
          border-left: 1px solid $light-gray;
        }
      }
    }
  }
}
.browser-widget {
  &:hover {
    img {
      filter: grayscale(0%);
      transition: all 0.3s ease;
      transform: scale(1.1) rotate(5deg);
    }
  }
  img {
    height: 80px;
    filter: grayscale(100%);
    transform: scale(1) rotate(0deg);
    transition: all 0.3s ease;
  }
  .flex-grow-1 {
    text-align: center;
    column-count: 3;
    column-rule: 1px solid $light-color;
    p {
      margin-bottom: 5px;
    }
    span {
      margin-bottom: 0;
      color: $theme-body-sub-title-color;
    }
    h4 {
      color: $theme-body-font-color;
      margin-bottom: 0;
      font-size: $btn-lg-font-size;
      span {
        color: #333333;
      }
    }
  }
}
%clock-widget {
  position: absolute;
  width: 24px;
  height: 234px;
  top: 0;
  left: 102px;
  margin-top: -30px;
  margin-left: -25px;
}
.cal-date-widget {
  .react-datepicker {
    padding: 0;
    min-height: 325px;
  }
  .cal-info h2 {
    font-size: 100px;
    color: $light-gray;
  }
}
.weather-widget-two {
  background: url(../../images/other-images/wallpaper.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  color: $white;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.3;
    filter: blur(30px);
    background-color: rgba($primary-color, 0.05);
    border-radius: 10px;
    top: 0;
  }
  .card-body {
    position: relative;
    z-index: 1;
  }
  svg {
    path.climacon_component-stroke {
      fill: $white;
    }
  }
  .widget-list {
    .climacon_component-fill {
      fill: $white;
    }
    svg.climacon {
      width: 70px;
      height: 70px;
    }
    ul {
      li {
        padding: 16px 0;
        + li {
          border-top: 1px solid rgba($white, 0.2);
        }
      }
    }
    .d-flex {
      align-items: center;
      .flex-grow-1 {
        margin-left: 30px;
        h5 {
          font-size: 22px;
          margin-bottom: 0;
        }
      }
      span {
        font-size: 18px;
      }
    }
  }
  .top-bg-whether {
    position: absolute;
    top: -50px;
    right: -28px;
    opacity: 0.1;
    svg {
      width: 200px;
      height: 200px;
    }
  }
  .num {
    font-weight: 600;
  }
  .climacon_component-stroke {
    stroke: $white;
  }
  .bottom-whetherinfo {
    svg {
      width: 150px;
      height: 150px;
      opacity: 0.1;
      color: $white;
      position: relative;
      top: 0;
      left: -35px;
    }
    .whether-content {
      text-align: right;
      position: relative;
      top: 5px;
      color: $white;
    }
  }
}
.mobile-clock-widget {
  position: relative;
  padding: 25px;
  z-index: 1;
  text-align: center;
  .react-clock{
    margin: 0 auto;
  }
  > div {
    > div {
      &:first-child {
        margin: 0 auto;
      }
    }
  }
  &::after {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #19191b;
    opacity: 0.3;
    border-radius: 10px;
  }
  .bg-svg {
    position: absolute;
    top: -4px;
    left: -25px;
    svg {
      width: 150px;
      height: 150px;
      opacity: 0.08;
    }
  }
  .clock {
    padding: 0;
    position: relative;
    list-style: none;
    margin: 0 auto;
    height: 175px;
    width: 175px;
    display: block;
    background: url(../../images/other-images/clock-face.png) 5% center
      no-repeat;
  }
  .sec {
    @extend %clock-widget;
    background: url(../../images/sec.svg);
    z-index: 3;
  }
  .min {
    background: url(../../images/min.svg);
    z-index: 2;
  }
  .hour {
    background: url(../../images/hour.svg);
    z-index: 1;
  }
}
.min {
  @extend %clock-widget;
}
.hour {
  @extend %clock-widget;
}
.general-widget {
  .cal-date-widget {
    .datepicker {
      width: auto;
    }
  }
}
//general widget css start
.mobile-clock-widget {
  #date {
    margin-top: 30px;
  }
}
.flot-chart-container {
  height: 250px;
}
// calender widget
.calender-widget {
  .cal-img {
    background: url(../../images/other-images/calender-bg.png);
    background-size: cover;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    height: 300px;
  }
  .cal-date {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    margin-top: -45px;
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    h5 {
      margin-bottom: 0;
      line-height: 1.5;
      padding: 17px;
      font-weight: 600;
      font-size: $btn-lg-font-size;
    }
  }
  .cal-desc {
    h3 {
      font-size: 22px;
      margin-bottom: 6px;
      text-transform: capitalize;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      color: $light-text;
    }
  }
}
.contact-form {
  .theme-form {
    border: 1px solid $light-semi-gray;
    padding: 30px;
    border-radius: 4px;
    .form-icon {
      width: 53px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 1px solid $light-semi-gray;
      border-radius: 100%;
      font-size: 28px;
      background-color: $white;
      color: $primary-color;
      margin-top: -55px;
      margin-bottom: 15px;
    }
    label {
      font-weight: 600;
    }
    input,
    textarea {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .btn {
    padding: 10px 30px;
  }
}
//chart widget css
.chart-widget-top {
  #chart-widget1,
  #chart-widget2,
  #chart-widget3 {
    margin-bottom: -14px;
    .apexcharts-xaxistooltip {
      display: none;
    }
  }
}
.bar-chart-widget {
  .top-content {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .earning-details {
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    letter-spacing: 1px;
    i {
      font-size: 230px;
      position: absolute;
      opacity: 0.1;
      right: -30px;
      top: 0;
      &:hover {
        transform: rotate(-5deg) scale(1.1);
        transition: all 0.3s ease;
      }
    }
  }
  .num {
    font-weight: 400;
    font-size: 19px;
    .color-bottom {
      color: $black !important;
    }
  }
}
.bottom-content {
  span {
    color: $theme-body-sub-title-color;
  }
  .block-bottom {
    display: block;
  }
}
// user status
.user-status {
  table {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    thead {
      tr {
        th {
          border-top: 0;
          font-size: 16px;
          color: $dark-color;
          font-weight: 500;
          padding-top: 0;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
        td {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }
  }
}
// employee status
.employee-status {
  .d-flex {
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .flex-grow-1 {
      h5 {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
  table {
    tr {
      th {
        padding-top: 0;
      }
      th,
      td {
        vertical-align: middle;
        &:last-child {
          text-align: right;
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
          min-width: 250px;
        }
        &:nth-child(n + 2) {
          min-width: 150px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
}
// widget feedback
.widget-feedback {
  padding-bottom: 0 !important;
  .apexcharts-canvas {
    margin: 0 auto;
  }
  .feedback-top {
    svg {
      width: 130px;
      height: auto;
      margin-bottom: 20px;
    }
  }
  h3 {
    font-size: 20px;
    color: $semi-dark;
    margin-bottom: 10px;
  }
  h6 {
    color: $light-text;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
  ul {
    display: flex;
    align-items: center;
    border-top: 1px solid $light-gray;
    margin-top: 25px;
    li {
      width: 100%;
      text-align: center;
      padding-top: 27px !important;
      padding-bottom: 27px !important;
      h4 {
        color: $light-text;
        font-size: 16px;
      }
      h5 {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 600;
      }
      &:nth-child(n + 2) {
        border-left: 1px solid $light-gray;
      }
    }
  }
}
// activity media
.activity-media {
  margin: -20px 0;
  .d-flex {
    padding: 20px 0;
    .recent-circle {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      margin-top: 6px;
      align-self: start;
    }
    .flex-grow-1 {
      margin-left: 20px;
      h6 {
        line-height: 1.6;
        font-size: 15px;
      }
      i {
        vertical-align: middle;
      }
      svg {
        width: 12px;
        height: 12px;
      }
      span {
        font-size: 12px;
        color: $light-text;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }
    &:nth-child(n + 2) {
      border-top: 1px solid $light-gray;
    }
  }
}
// custom profile
.custom-profile {
  .card-profile {
    position: relative;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;

    img {
      background-color: transparent;
    }
  }
  .profile-details {
    margin-top: 20px;
    h4 {
      font-size: 20px;
      color: $theme-body-font-color;
    }
    h6 {
      font-size: 16px;
      color: $light-text;
      margin-bottom: 0;
    }
  }
  .card-social {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $light-gray;
      border-radius: 5px;
      a {
        color: $white !important;
      }
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
      &.bg-fb {
        background-color: rgba($fb, 0.8);
        &:hover {
          background-color: $fb;
        }
      }
      &.bg-twitter {
        background-color: rgba($twitter, 0.8);
        &:hover {
          background-color: $twitter;
        }
      }
      &.bg-insta {
        background-color: rgba($insta, 0.8);
        &:hover {
          background-color: $insta;
        }
      }
      &.bg-gplus {
        background-color: rgba($google-plus, 0.8);
        &:hover {
          background-color: $google-plus;
        }
      }
      &.bg-linkedin {
        background-color: rgba($linkedin, 0.8);
        &:hover {
          background-color: $linkedin;
        }
      }
    }
  }
  .card-footer {
    background-color: transparent;
    padding: 0 !important;
    h6 {
      font-size: 16px;
    }
    h5 {
      font-size: 20px;
    }
    > div {
      text-align: center;
      margin: 0;
      h6 {
        color: $light-text;
      }
      h5 {
        margin-bottom: 0;
      }
      div {
        padding-top: 30px;
        padding-bottom: 30px;
        + div {
          border-left: 1px solid $light-gray;
        }
      }
    }
  }
}
.browser-table {
  table {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }
    thead {
      th {
        border-top: 0;
        font-size: 16px;
        padding-top: 0;
        border-bottom-width: 1px;
      }
    }
    tr {
      th,
      td {
        vertical-align: middle;
        font-family: $font-roboto, $font-serif;
        font-weight: 500;
        border-color: $light-gray;
      }
      td {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $light-text;
        padding-top: 22px;
        padding-bottom: 20px;
        img {
          height: 46px;
          transition: 0.5s;
        }
      }
      &:hover {
        td {
          img {
            filter: grayscale(100%);
            transition: 0.5s;
          }
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
}
.ecommerce-widget {
  box-shadow: none;
  border: 1px solid #f1f3ff !important;
  border-radius: 5px;
  .total-num {
    color: $theme-body-font-color;
    margin: 0;
    letter-spacing: 1px;
    span {
      color: $black;
    }
  }
  .row {
    align-items: center;
  }
  .progress-showcase {
    margin-top: 30px;
  }
  span {
    color: $theme-body-sub-title-color;
    margin: 0;
    cursor: pointer;
  }
  .icon {
    color: $light-semi-gray;
    font-size: 40px;
  }
  .flot-chart-container {
    height: 100px;
    padding: 0;
    margin: 0;
    border: transparent;
  }
  .morris-default-style {
    display: none !important;
  }
  svg {
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  }
}
// testimonial
.testimonial {
  text-align: center;
  i {
    font-size: 60px;
    color: $light-text;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.1;
    z-index: -1;
  }
  span {
    color: $theme-body-sub-title-color;
  }
  img {
    margin: 0 auto;
    border-radius: 50%;
  }
  h5 {
    color: $theme-body-font-color;
    padding-top: 20px;
    margin-bottom: 0;
    font-size: 20px;
  }
  p {
    font-size: 14px;
    color: $theme-body-sub-title-color;
    font-style: italic;
    margin-top: 20px;
  }
}
// order widget
.order-widget {
  .heder-widget {
    span {
      color: $light-text;
    }
    h3 {
      margin-bottom: 0;
    }
    ul {
      li {
        color: $light-text;
        font-weight: 500;
      }
    }
  }
  .new-order {
    h6,
    span {
      color: $light-text;
    }
    .progress {
      height: 20px;
      margin-top: 20px;
    }
    ul {
      margin-top: 40px;
      display: flex;
      align-items: center;
      li {
        width: 100%;
        h5 {
          margin-bottom: 0;
        }
      }
    }
  }
}
.speed-chart {
  .chart-container {
    width: 100%;
    height: 530px;
  }
  .content {
    margin-top: -200px;
    margin-bottom: 20px;
    h4 {
      font-weight: 600;
      padding-top: 2px;
    }
  }
}
.status-widget {
  svg {
    width: 20px;
    height: 20px;
    vertical-align: text-top;
  }
  .card {
    .card-header {
      h5 {
        line-height: 1.38;
      }
    }
  }
  .status-chart {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
}
.small-chart-widget .chart-container {
  height: 311px;
  padding: 0;
  margin: 0;
  border: none;
}
.small-chart-widget {
  .card {
    .card-body {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
.donut-chart-widget .chart-container {
  height: 300px;
  border: 0;
  margin: 0;
  padding: 0;
}
.status-details {
  h4 {
    font-weight: 600;
    span {
      color: inherit;
    }
  }
  span {
    color: $theme-body-sub-title-color;
  }
}
.status-chart .chart-container {
  height: 200px;
  border: 0;
  padding: 0;
  margin: 0;
}
.map-chart .chart-container {
  width: 100%;
  height: 500px;
}
.serial-chart {
  width: 100%;
}
.live-products,
.turnover,
.uses,
.monthly {
  height: 300px;
}
.live-products {
  .ct-series-a {
    .ct-area,
    .ct-point,
    .ct-line,
    .ct-bar,
    .ct-slice-donut {
      stroke: rgba(255, 255, 255, 0.77);
    }
  }
  .ct-label {
    fill: $white;
    color: $white;
  }
  .ct-grid {
    stroke: rgba(255, 255, 255, 0.7);
  }
}
.turnover,
.uses,
.monthly {
  .ct-series-a {
    .ct-area,
    .ct-point,
    .ct-line,
    .ct-bar,
    .ct-slice-donut {
      stroke: rgba(255, 255, 255, 1);
      fill: transparent;
    }
  }
  .ct-series-b {
    .ct-area,
    .ct-point,
    .ct-line,
    .ct-bar,
    .ct-slice-donut {
      stroke: rgba(255, 255, 255, 0.7);
      fill: transparent;
    }
  }
  .ct-series-c {
    .ct-area,
    .ct-point,
    .ct-line,
    .ct-bar,
    .ct-slice-donut {
      stroke: rgba(255, 255, 255, 0.4);
      fill: transparent;
    }
  }
  .ct-label {
    fill: $white;
    color: $white;
  }
  .ct-grid {
    stroke: rgba(255, 255, 255, 0.7);
  }
}
// draggable card
#draggableMultiple {
  .ui-sortable-handle {
    .card {
      cursor: move;
    }
  }
}
// tilt page
.tilt-showcase {
  ul {
    li {
      .line {
        padding-top: 10px;
      }
    }
  }
  .pre-mt {
    margin-top: 5px;
  }
}
// wow page
.wow-title {
  h5 {
    display: flex;
    align-items: center;
    font-size: 18px;
    .badge {
      -webkit-text-fill-color: $white;
    }
  }
}
// alert page
.alert-center {
  display: flex !important;
  align-items: center;
}
// box-shadow
.box-shadow-title {
  .sub-title {
    margin: 30px 0;
  }
}
// tour page
.hovercard {
  .info {
    .social-media {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          padding-top: 3px;
          a {
            width: 40px;
            height: 40px;
            background-color: rgba($primary-color, 0.05);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &:hover {
            a {
              background-color: $primary-color;
              i {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .cardheader {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
// sticky page
.sticky-header-main {
  .card {
    .card-header {
      h3 {
        line-height: 35px;
        a {
          color: $white;
          -webkit-text-fill-color: $white;
        }
      }
    }
  }
}
// radio-checkbox-control page
.custom-radio-ml {
  margin-left: 3px;
}
// date range picker page
.daterangepicker {
  .ltr {
    tr {
      td {
        &.active {
          color: $white;
        }
      }
    }
  }
}
// typeahead page
.twitter-typeahead {
  display: block !important;
}
span.twitter-typeahead {
  .league-name {
    font-size: 16px;
    padding: 6px 10px 0;
  }
  .tt-menu {
    float: left;
    width: 100%;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }
  .tt-suggestion {
    color: $dark-editor-document;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid $light-semi-gray;
    background: $white;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: $white;
    }
    &.active,
    &:active {
      color: $white;
      background-color: var(--theme-default);
    }
  }
}
#scrollable-dropdown-menu {
  .twitter-typeahead {
    .tt-menu {
      .tt-dataset {
        max-height: 100px;
        overflow-y: auto;
      }
    }
  }
}
.current-sale-container {
  .apexcharts-xaxistooltip {
    color: var(--theme-default);
    background: rgba($primary-color, 0.1);
    border: 1px solid var(--theme-default);
  }
  .apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: var(--theme-default);
  }
  .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: rgba($primary-color, 0.1);
    color: var(--theme-default);
  }
}
// bootstrap basic table page
.card-block {
  .table-responsive {
    .table {
      caption {
        padding-left: 10px;
      }
    }
    .table-bordered {
      td {
        vertical-align: middle;
      }
    }
  }
  .table-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .default-checkbox-align {
    #checkbox1 {
      margin-right: 10px;
    }
    #radio {
      margin-right: 5px;
    }
  }
}
// chartjs page
.chart-vertical-center {
  display: flex;
  justify-content: center;

  #myDoughnutGraph,
  #myPolarGraph {
    width: auto !important;
  }
}
// ckeditor page
.cke_focus {
  padding: 15px;
  margin-top: 13px;
}
// ace-code-editor page
#editor {
  .ace_scroller {
    .ace_content {
      .ace_layer {
        .ace_print-margin {
          visibility: hidden !important;
        }
      }
    }
  }
}
// Helper classes page css
.helper-classes {
  padding: 30px;
  margin-bottom: 0;
  white-space: pre-line;
}
.table {
  thead {
    th {
      border-bottom: 2px solid $light-gray;
    }
  }
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-default {
  .d-flex-dashboard {
    align-items: center;
    .icon-box {
      width: 50px;
      height: 32px;
      background-color: rgba($primary-color, 0.08);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: var(--theme-default);
        font-size: 22px;
      }
    }
  }
  .card-body {
    padding-top: 0;
    &.chat-box {
      .left-side-chat {
        .flex-grow-1 {
          .img-profile {
            width: 40px;
            height: 40px;
            img {
              border-radius: 100%;
            }
          }
          .main-chat {
            margin-left: 15px;
            .message-main {
              span {
                background-color: var(--theme-default);
                border-radius: 30px;
                border-bottom-left-radius: 0;
                color: $white;
              }
            }
            .sub-message {
              margin-top: 10px;
              span {
                border-bottom-left-radius: 30px;
                border-top-left-radius: 0;
              }
            }
            &:last-child {
              .sub-message {
                span {
                  border-top-left-radius: 30px;
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
        }
      }
      .right-side-chat {
        margin: 15px 0;
        .flex-grow-1 {
          .message-main {
            span {
              background-color: $white;
              box-shadow: 0px 0px 20px rgba(25, 124, 207, 0.1);
              border-radius: 30px;
              color: var(--theme-default);
              opacity: 100%;
              border-bottom-right-radius: 0;
              text-align: center;
              &.loader-span {
                .dot {
                  display: inline-block;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  margin-right: 4px;
                  background: var(--theme-default);
                  animation: wave 1.3s linear infinite;
                  padding: 0;
                  &:nth-child(2) {
                    animation-delay: -1.1s;
                  }
                  &:nth-child(3) {
                    animation-delay: -0.9s;
                  }
                }
                @keyframes wave {
                  0%,
                  60%,
                  100% {
                    transform: initial;
                  }
                  30% {
                    transform: translateY(-9px);
                  }
                }
              }
            }
          }
        }
      }
      .d-flex {
        .flex-grow-1 {
          .message-main {
            span {
              display: inline-block;
              padding: 12px 18px;
              font-weight: 400;
            }
          }
        }
        p {
          font-size: 10px;
          margin-top: 14px;
          color: var(--theme-default);
        }
      }
      .input-group {
        margin: 30px 0;
        margin-bottom: 0;
        align-items: center;
        .form-control {
          border: none;
          border-radius: 0;
          border-left: 1px solid var(--theme-default);
          font-size: 16px;
          &::placeholder {
            color: $dark-editor-document;
            font-weight: 500;
            font-size: 16px;
          }
          &:focus {
            box-shadow: none;
          }
        }
        .send-msg {
          width: 50px;
          height: 45px;
          background-color: rgba($primary-color, 0.1);
          border-radius: 25% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 20px;
            color: var(--theme-default);
            vertical-align: middle;
            transform: rotate(45deg);
            margin-left: -3px;
          }
        }
      }
    }
  }
}
.date-picker-setting {
  background-color: rgba($primary-color, 0.1);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  i {
    color: var(--theme-default);
  }
}
.default-datepicker {
  .datepicker-inline {
    .datepicker {
      width: auto;
      background: $white;
      box-shadow: none;
      padding: 0;
      .datepicker--content {
        .datepicker--days {
          .datepicker--days-names {
            margin: 60px 0 0;
            padding: 15px 0;
            .datepicker--day-name {
              color: $dark-editor-document;
              font-size: 14px;
            }
          }
          .datepicker--cells {
            .datepicker--cell-day {
              height: 55px;
              border-radius: 0;
              color: $dark-editor-document;

              &.-other-month- {
                color: $dark-editor-document;
                opacity: 20%;
              }
            }
            .datepicker--cell {
              font-weight: 500;
              font-size: 16px;
              border-radius: 0;
              z-index: 0;
              &.-selected- {
                background: var(--theme-default);
                color: $white;
                position: relative;
                box-shadow: 0px 0px 13px 0 rgba($primary-color, 0.5);
              }
              &.-current- {
                box-shadow: 0px 0px 15px -5px rgba($primary-color, 0.5);
              }
              &.-focus- {
                color: $white;
                box-shadow: 0px 0px 13px 0 rgba($primary-color, 0.5);
              }
            }
          }
        }
        .datepicker-cell {
          .datepicker--cell-month {
            &.-current- {
              &.-selected- {
                background-color: var(--theme-default);
              }
            }
          }
        }
      }
      .datepicker--nav {
        border-bottom: none;
        padding: 0;
        text-transform: capitalize;
        margin-top: 0;
        .datepicker--nav-action {
          display: none;
        }
      }
      .datepicker--nav-title {
        color: $dark-editor-document;
        font-size: 22px;
        font-weight: 600;
        i {
          margin-left: 10px;
          font-weight: 400;
          font-size: 22px;
          color: $dark-editor-document;
        }
      }
    }
  }
}
@media screen and (max-width: 1660px) and (min-width: 1366px) {
  .activity-media{
    .d-flex{
      padding: 12px 0;
    }
  }
  .custom-profile {
    .card-footer {
      > div {
        div {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      h6 {
        font-size: 14px;
      }
      h5 {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 1580px) and (min-width: 1401px) {
  .user-status{
    table{
      tbody{
        tr{
          td{
            padding-top: 18px;
            padding-bottom: 18px;
          }
        }
      }
    }
  }
  .general-widget {
    .custom-profile{
      .card-profile{
        img{
          height: 180px;
        }
      }
    }
    .activity-media {
      .d-flex {
        padding: 10px 0;
      }
    } 
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .browser-table{
    table{
      tr{
        td{
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }
  .social-widget-card{
    .d-flex{
      .flex-grow-1{
        margin-left: 12px;
      }
      h2{
        font-size: 20px;
      }
      .social-font{
        width: 48px;
        height: 48px;
      }
    }
  }
  .static-widget{
    margin-bottom: 26px;
    h2{
      font-size: 20px;
    }
  }
  .general-widget{
    .custom-profile{
      .card-profile{
        img{
          height: 200px;
        }
      }
    }
  }
  .custom-profile{
    .card-footer{
      > div{
        margin: 0;
        div{
          padding: 28px 10px;
        }
      }
      h6{
        font-size: 14px;
      }
    }
  }
  .order-widget{
    .new-order{
      .progress{
        margin-top: 14px;
      }
    }
  }  
  .testimonial{
    p{
      margin-top: 4px;
    }
  }
  .activity-media{
    .d-flex{
      padding: 12px 0;
    }
  }
  .widget-joins{
    .widget-card{
      padding: 18px 14px;
      .widget-icon{
        width: 32px;
        height: 32px;
        svg{
          width: 18px;
          height: 18px;
        }
        i{
          font-size: 24px;
        }
      }
      h5{
        font-size: 13px;
      }
      h6{
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .weather-widget-two {
    .widget-list {
      .d-flex {
        .flex-grow-1 {
          margin-left: 12px;
        }
      }
    }
  }
  .widget-joins {
    .widget-card {
      padding: 20px;
    }
  }
  .social-widget-card {
    .d-flex {
      .social-font {
        width: 50px;
        height: 50px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .widget-joins {
    .widget-card {
      padding: 15px;
      .widget-icon {
        width: 40px;
        height: 40px;
        i {
          font-size: 22px;
        }
        svg {
          width: 18px;
          height: 18px;
        }
      }
      h6 {
        font-size: 16px;
      }
      h5 {
        font-size: 12px;
      }
    }
  }
  .widget-feedback {
    .feedback-top {
      svg {
        width: 80px;
        margin-bottom: 10px;
      }
    }
    ul {
      li {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .custom-profile {
    .card-footer {
      h6 {
        font-size: 14px;
      }
      h5 {
        font-size: 16px;
      }
      > div {
        div {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
    .card-social {
      li {
        width: 30px;
        height: 30px;
        i {
          font-size: 13px;
        }
      }
    }
  }
  .calender-widget {
    .cal-desc {
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
/**=====================
    51. General widget CSS Ends
==========================**/